import { graphql } from "gatsby";
import React from "react";
import ContentBlock from "../components/LandingContentBlock/ContentBlock";
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"

export default ({ data: { markdownRemark } }) => {
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <ContentBlock class={'sm-content'}>
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
        <div className="post-body" dangerouslySetInnerHTML={{ __html: html }} />
      </ContentBlock>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`;